<template>
  <div class="w-full flex flex-col justify-between">
    <section class="-mt-4">
      <b-tabs class="w-full">
        <b-tab title="Geral">
          <GeneralNcmInformation class="mt-8" :viewModel="viewModel" />
        </b-tab>
        <b-tab title="Complemento">
          <SupplementaryInformationNcm class="mt-8" :viewModel="viewModel" />
        </b-tab>
      </b-tabs>
    </section>
    <div class="flex">
      <span class="text-red-600"
        >Os campos com * são de preenchimento obrigatório</span
      >
    </div>
    <footer class="w-full flex items-center justify-start gap-5 my-10">
      <Button
        class="bg-green-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-green-600 transition-all duration-300"
        buttonText="Confirmar"
        :onClick="Edit"
      />
      <Button
        class="bg-red-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-red-600 transition-all duration-300"
        buttonText="Cancelar"
        :onClick="redirectUrl"
      />
    </footer>
    <Modal
      v-if="modalIsOpen"
      :modalIsOpen.sync="modalIsOpen"
      :closeModal="closeModal"
      :modalText="$t('commons.modalTextEdit')"
    />
    <Modal
      v-if="modalError"
      :modalIsOpen.sync="modalError"
      :closeModal="closeModal"
      :modalText="$t('commons.modalErrorCreate')"
    />
    <Modal
      v-if="modalErroSalvar"
      :modalIsOpen.sync="modalErroSalvar"
      :closeModal="closeModal"
      :modalText="$t('commons.modalErroSalvar')"
    />
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";

import GeneralNcmInformation from "./GeneralNcmInformation";
import SupplementaryInformationNcm from "./SupplementaryInformationNcm";
import Button from "@core/components/commons/button/Button";
import Modal from "@core/components/commons/modal/Modal";

export default {
  name: "ManageNcmEdit",
  components: {
    BTabs,
    BTab,
    GeneralNcmInformation,
    SupplementaryInformationNcm,
    Button,
    Modal,
  },
  data() {
    return {
      modalIsOpen: false,
      modalError: false,
      modalErroSalvar: false,
      viewModel: {
        id: null,
        contaId: null,
        integracaoId: null,
        codigoNcm: null,
        descricao: null,
        ncmServico: null,
        percentualMva: null,
        codigoNcmEx: null,
        aliquotaNacional: null,
        aliquotaImportacao: null,

        cstPisEntradaId: null,
        cstPisEntradaCodTributo: null,

        cstPisSaidaId: null,
        cstPisSaidaCodTributo: null,

        cstCofinsEntradaId: null,
        cstCofinsEntradaCodTributo: null,

        cstCofinsSaidaId: null,
        cstCofinsSaidaCodTributo: null,

        aliquotaPis: null,
        aliquotaCofins: null,
        Estados: [
          {
            id: null,
            contaId: null,
            ncmId: null,
            integracaoId: null,
            ufOrigemId: null,
            ufOrigem: null,
            ufDestinoId: null,
            ufDestino: null,
            cst: null,
            cstId: null,
            csosn: null,
            csosnId: null,
            aliquotaICMS: null,
            aliquotaICMSInterna: null,
            aliquotaMVA: null,
            aliquotaFCP: null,
          },
        ],
      },
    };
  },
  created() {
    this.RecuperarUser(this.$router.currentRoute.params.id);
  },
  methods: {
    openModal() {
      this.modalIsOpen = true;
    },
    closeModal(event) {
      if (event.target === event.currentTarget) {
        this.modalIsOpen = false;
        this.modalError = false;
        this.modalErroSalvar = false;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    RecuperarUser(id) {
      this.$http({
        url: `/ncm/obter/${id}`,
        method: "Get",
      }).then((response) => {
        this.viewModel.id = response.data.id;
        this.viewModel.contaId = response.data.contaId;
        this.viewModel.integracaoId = response.data.integracaoId;
        this.viewModel.codigoNcm = response.data.codigoNcm;
        this.viewModel.descricao = response.data.descricao;
        this.viewModel.ncmServico = response.data.ncmServico;
        this.viewModel.percentualMva = response.data.percentualMva;
        this.viewModel.codigoNcmEx = response.data.codigoNcmEx;
        this.viewModel.aliquotaNacional = response.data.aliquotaNacional;
        this.viewModel.aliquotaImportacao = response.data.aliquotaImportacao;
        this.viewModel.cstPisEntradaId = response.data.cstPisEntradaId;
        this.viewModel.cstPisSaidaId = response.data.cstPisSaidaId;
        this.viewModel.cstCofinsEntradaId = response.data.cstCofinsEntradaId;
        this.viewModel.cstCofinsSaidaId = response.data.cstCofinsSaidaId;
        this.viewModel.aliquotaPis = response.data.aliquotaPis;
        this.viewModel.aliquotaCofins = response.data.aliquotaCofins;

        var estado = response.data.estados;

        this.viewModel.Estados[0].id = estado[0].id;
        this.viewModel.Estados[0].contaId = estado[0].contaId;
        this.viewModel.Estados[0].ncmId = estado[0].ncmId;
        this.viewModel.Estados[0].integracaoId = estado[0].integracaoId;
        this.viewModel.Estados[0].ufOrigemId = estado[0].ufOrigemId;
        this.viewModel.Estados[0].ufOrigem = estado[0].ufOrigem;
        this.viewModel.Estados[0].ufDestinoId = estado[0].ufDestinoId;
        this.viewModel.Estados[0].ufDestino = estado[0].ufDestino;
        this.viewModel.Estados[0].cst = estado[0].cst;
        this.viewModel.Estados[0].cstId = estado[0].cstId;
        this.viewModel.Estados[0].csosn = estado[0].csosn;
        this.viewModel.Estados[0].csosnId = estado[0].csosnId;
        this.viewModel.Estados[0].aliquotaICMS = estado[0].aliquotaICMS;
        this.viewModel.Estados[0].aliquotaICMSInterna =
          estado[0].aliquotaICMSInterna;
        this.viewModel.Estados[0].aliquotaMVA = estado[0].aliquotaMVA;
        this.viewModel.Estados[0].aliquotaFCP = estado[0].aliquotaFCP;

        this.RecuperarPisCofins(this.viewModel.cstPisEntradaId, "PisEntrada");
        this.RecuperarPisCofins(this.viewModel.cstPisSaidaId, "PisSaida");
        this.RecuperarPisCofins(
          this.viewModel.cstCofinsEntradaId,
          "CofinsEntrada"
        );
        this.RecuperarPisCofins(this.viewModel.cstCofinsSaidaId, "CofinsSaida");
      });
    },
    RecuperarPisCofins(id, campo) {
      this.$http({
        url: `/tributo/obter/${id}`,
        method: "GET",
      }).then((res) => {
        if (campo == "PisEntrada") {
          this.viewModel.cstPisEntradaCodTributo = res.data.codigoTributo;
        }
        if (campo == "PisSaida") {
          this.viewModel.cstPisSaidaCodTributo = res.data.codigoTributo;
        }
        if (campo == "CofinsEntrada") {
          this.viewModel.cstCofinsEntradaCodTributo = res.data.codigoTributo;
        }
        if (campo == "CofinsSaida") {
          this.viewModel.cstCofinsSaidaCodTributo = res.data.codigoTributo;
        }
      });
    },
    Edit() {
      if (this.Validate()) {
        this.$http({
          url: `/Ncm/editar`,
          data: this.viewModel,
          method: "POST",
        })
          .then(() => {
            this.modalIsOpen = true;
            setTimeout(() => this.$router.push({ name: "ncm" }), 3000);
          })
          .catch(() => (this.modalErroSalvar = true));
      }
    },
    Validate() {
      if (
        this.viewModel.codigoNcm == null ||
        this.viewModel.codigoNcm == "" ||
        this.viewModel.descricao == null ||
        this.viewModel.descricao == ""
      ) {
        this.modalError = true;
        return false;
      }
      if (
        this.viewModel.Estados[0].id == null &&
        this.viewModel.Estados[0].contaId == null &&
        this.viewModel.Estados[0].ncmId == null &&
        this.viewModel.Estados[0].integracaoId == null &&
        this.viewModel.Estados[0].ufOrigemId == null &&
        this.viewModel.Estados[0].ufOrigem == null &&
        this.viewModel.Estados[0].ufDestinoId == null &&
        this.viewModel.Estados[0].ufDestino == null
      ) {
        if (
          (this.viewModel.Estados[0].aliquotaICMS != null &&
            this.viewModel.Estados[0].aliquotaICMS != "") ||
          (this.viewModel.Estados[0].aliquotaICMSInterna != null &&
            this.viewModel.Estados[0].aliquotaICMSInterna != "") ||
          (this.viewModel.Estados[0].aliquotaMVA != null &&
            this.viewModel.Estados[0].aliquotaMVA != "") ||
          (this.viewModel.Estados[0].aliquotaFCP != null &&
            this.viewModel.Estados[0].aliquotaFCP != "")
        ) {
          this.modalError = true;
          return false;
        }

        this.viewModel.Estados = null;
        return true;
      }

      if (
        this.viewModel.Estados[0].ufOrigemId == null ||
        this.viewModel.Estados[0].ufOrigem == null ||
        this.viewModel.Estados[0].ufDestinoId == null ||
        this.viewModel.Estados[0].ufDestino == null ||
        this.viewModel.Estados[0].aliquotaICMS == null ||
        this.viewModel.Estados[0].aliquotaICMSInterna == null ||
        this.viewModel.Estados[0].aliquotaMVA == null ||
        this.viewModel.Estados[0].aliquotaFCP == null ||
        this.viewModel.Estados[0].ufOrigemId == "" ||
        this.viewModel.Estados[0].ufOrigem == "" ||
        this.viewModel.Estados[0].ufDestinoId == "" ||
        this.viewModel.Estados[0].ufDestino == "" ||
        this.viewModel.Estados[0].aliquotaICMS == "" ||
        this.viewModel.Estados[0].aliquotaICMSInterna == "" ||
        this.viewModel.Estados[0].aliquotaMVA == "" ||
        this.viewModel.Estados[0].aliquotaFCP == ""
      ) {
        this.modalError = true;
        return false;
      }
      if (this.viewModel.Estados[0].id == null) {
        this.viewModel.Estados[0].id = "3c5d1b6c-c0c3-4774-a307-992c25c2bda2";
      }
      if (this.viewModel.Estados[0].contaId == null) {
        this.viewModel.Estados[0].contaId =
          "3c5d1b6c-c0c3-4774-a307-992c25c2bda2";
      }
      if (this.viewModel.Estados[0].ncmId == null) {
        this.viewModel.Estados[0].ncmId = this.viewModel.id;
      }
      return true;
    },
    redirectUrl() {
      return this.$router.push("/ncm");
    },
  },
};
</script>

<style></style>
