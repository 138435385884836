<template>
  <section>
    <b-form>
      <!-- NCM -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-6">
              <input-required
                v-model="viewModel.codigoNcm"
                :label="$t('sectionNcm.Create.Ncm')"
                :rules="{ required: true, max: 10 }"
                max="10"
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <CheckboxDefault
                label="NCM de serviço"
                v-model="viewModel.ncmServico"
              />
            </div>
          </div>
        </div>

        <!-- DESCRIÇÃO -->
        <div class="col-span-8 border-solid border-b border-gray-300 pb-2">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-12">
              <input-required
                v-model="viewModel.descricao"
                :label="$t('sectionNcm.Create.Description')"
                :rules="{ required: true, max: 50 }"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Resto -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4 mt-3">
        <div class="col-span-8 border-solid border-b border-gray-300">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 xl:col-span-3">
              <input-default
                v-model="viewModel.percentualMva"
                :label="$t('sectionNcm.Create.MvaPercent')"
                :rules="{ decimal: [10, 2], positive: true }"
                type="number"
              />
            </div>
            <div class="col-span-12 xl:col-span-3">
              <input-default
                v-model="viewModel.codigoNcmEx"
                :label="$t('sectionNcm.Create.Excode')"
                type="number"
              />
            </div>
            <div class="col-span-12 xl:col-span-3">
              <input-default
                v-model="viewModel.aliquotaNacional"
                :label="$t('sectionNcm.Create.NationalAliq')"
                :rules="{ decimal: [10, 2], positive: true }"
                type="number"
              />
            </div>
            <div class="col-span-12 xl:col-span-3">
              <input-default
                v-model="viewModel.aliquotaImportacao"
                :label="$t('sectionNcm.Create.ImportAliq')"
                :rules="{ decimal: [10, 2], positive: true }"
                type="number"
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <input-default
                v-model="viewModel.cstPisEntradaCodTributo"
                :rules="{ decimal: [24, 5], positive: true }"
                :label="$t('sectionNcm.Create.PisEntrance')"
                @onClickIcon="
                  ObterPisCofins(
                    viewModel.cstPisEntradaCodTributo,
                    'PisEntrada'
                  )
                "
                :hasIcon="true"
                max="24"
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <Dropdown
                v-model="viewModel.cstPisEntradaId"
                :Items="dropdownItemsPisCofins"
                :label="$t('sectionNcm.Create.Description')"
                @ItemSelectId="DropdownSelected($event, 'PisEntrada')"
              >
              </Dropdown>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <input-default
                v-model="viewModel.cstPisSaidaCodTributo"
                :rules="{ decimal: [24, 5], positive: true }"
                :label="$t('sectionNcm.Create.PisExit')"
                @onClickIcon="
                  ObterPisCofins(viewModel.cstPisSaidaCodTributo, 'PisSaida')
                "
                :hasIcon="true"
                max="24"
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <Dropdown
                v-model="viewModel.cstPisSaidaId"
                :Items="dropdownItemsPisCofins"
                :label="$t('sectionNcm.Create.Description')"
                @ItemSelectId="DropdownSelected($event, 'PisSaida')"
              >
              </Dropdown>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <input-default
                v-model="viewModel.cstCofinsEntradaCodTributo"
                :rules="{ decimal: [24, 5], positive: true }"
                :label="$t('sectionNcm.Create.CofinsEntrance')"
                @onClickIcon="
                  ObterPisCofins(
                    viewModel.cstCofinsEntradaCodTributo,
                    'CofinsEntrada'
                  )
                "
                :hasIcon="true"
                max="24"
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <Dropdown
                v-model="viewModel.cstCofinsEntradaId"
                :Items="dropdownItemsPisCofins"
                :label="$t('sectionNcm.Create.Description')"
                @ItemSelectId="DropdownSelected($event, 'CofinsEntrada')"
              >
              </Dropdown>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <input-default
                v-model="viewModel.cstCofinsSaidaCodTributo"
                :rules="{ decimal: [24, 5], positive: true }"
                :label="$t('sectionNcm.Create.CofinsExit')"
                @onClickIcon="
                  ObterPisCofins(
                    viewModel.cstCofinsSaidaCodTributo,
                    'CofinsSaida'
                  )
                "
                :hasIcon="true"
                max="24"
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <Dropdown
                v-model="viewModel.cstCofinsSaidaId"
                :Items="dropdownItemsPisCofins"
                :label="$t('sectionNcm.Create.Description')"
                @ItemSelectId="DropdownSelected($event, 'CofinsSaida')"
              >
              </Dropdown>
            </div>
          </div>
        </div>
        <div class="col-span-8"></div>
      </div>
      <!-- aliquota -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8 border-solid border-b border-gray-300">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 lg:col-span-4">
              <input-default
                v-model="viewModel.aliquotaPis"
                :label="$t('sectionNcm.Create.AliqPis')"
                :rules="{ decimal: [24, 5], positive: true }"
                type="number"
              />
            </div>
            <div class="col-span-12 lg:col-span-4 gap-4">
              <input-default
                v-model="viewModel.aliquotaCofins"
                :label="$t('sectionNcm.Create.AliqCofins')"
                :rules="{ decimal: [24, 5], positive: true }"
                type="number"
              />
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </section>
</template>

<script>
import { required } from "@validations";
import InputRequired from "@core/components/commons/inputs/InputRequired";
import InputDefault from "@core/components/commons/inputs/InputDefault";
import CheckboxDefault from "@core/components/commons/checkbox/CheckboxDefault";
import Dropdown from "@/@core/components/commons/selectDropdown/Dropdown.vue";
import { BForm } from "bootstrap-vue";

export default {
  components: {
    BForm,
    InputRequired,
    InputDefault,
    CheckboxDefault,
    Dropdown,
  },
  props: {
    viewModel: {
      type: Object,
    },
  },
  data() {
    return {
      required,
      dropdownItemsPisCofins: [],
    };
  },
  created() {
    this.ObterSeletorPisCofins();
  },
  methods: {
    ObterSeletorPisCofins() {
      this.$http({
        url: `/tributo/obter-seletor-tributos-tipo/${4}`,
        method: "GET",
      }).then((res) => {
        this.dropdownItemsPisCofins = res.data;
      });
    },
    ObterPisCofins(codigoTributo, campo) {
      if (codigoTributo == "" || codigoTributo == null) {
        if (campo == "PisEntrada") {
          this.viewModel.cstPisEntradaId = null;
        }
        if (campo == "PisSaida") {
          this.viewModel.cstPisSaidaId = null;
        }
        if (campo == "CofinsEntrada") {
          this.viewModel.cstCofinsEntradaId = null;
        }
        if (campo == "CofinsSaida") {
          this.viewModel.cstCofinsSaidaId = null;
        }
        return;
      }
      this.$http({
        url: `/tributo/obter-PisCofins-por-codigo-tributo/${codigoTributo}`,
        method: "GET",
      }).then((res) => {
        if (campo == "PisEntrada") {
          this.viewModel.cstPisEntradaId = res.data.id;
        }
        if (campo == "PisSaida") {
          this.viewModel.cstPisSaidaId = res.data.id;
        }
        if (campo == "CofinsEntrada") {
          this.viewModel.cstCofinsEntradaId = res.data.id;
        }
        if (campo == "CofinsSaida") {
          this.viewModel.cstCofinsSaidaId = res.data.id;
        }
      });
    },
    BuscaCodTributo(item, tipo) {
      this.$http({
        url: `/tributo/obter/${item}`,
        method: "GET",
      }).then((res) => {
        if (tipo == "PisEntrada") {
          this.viewModel.cstPisEntradaCodTributo = res.data.codigoTributo;
        } else if (tipo == "PisSaida") {
          this.viewModel.cstPisSaidaCodTributo = res.data.codigoTributo;
        } else if (tipo == "CofinsEntrada") {
          this.viewModel.cstCofinsEntradaCodTributo = res.data.codigoTributo;
        } else if (tipo == "CofinsSaida") {
          this.viewModel.cstCofinsSaidaCodTributo = res.data.codigoTributo;
        }
      });
    },
    DropdownSelected(item, tipo) {
      if (tipo == "PisEntrada") {
        this.viewModel.cstPisEntradaId = item;
        this.BuscaCodTributo(this.viewModel.cstPisEntradaId, tipo);
      } else if (tipo == "PisSaida") {
        this.viewModel.cstPisSaidaId = item;
        this.BuscaCodTributo(this.viewModel.cstPisSaidaId, tipo);
      } else if (tipo == "CofinsEntrada") {
        this.viewModel.cstCofinsEntradaId = item;
        this.BuscaCodTributo(this.viewModel.cstCofinsEntradaId, tipo);
      } else if (tipo == "CofinsSaida") {
        this.viewModel.cstCofinsSaidaId = item;
        this.BuscaCodTributo(this.viewModel.cstCofinsSaidaId, tipo);
      }
    },
  },
};
</script>
