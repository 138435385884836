<template>
  <section>
    <b-form>
      <!-- estado -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8 border-solid border-b border-gray-300 pb-2">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-3">
              <Dropdown
                :Items="dropdownItemsEstados"
                v-model="viewModel.Estados[0].ufOrigemId"
                :valueDefault="$t('sectionNcm.Create.Uf')"
                :label="$t('sectionNcm.Create.Uf')"
                @ItemSelectId="DropdownSelected($event, 'UfOrigem')"
              >
              </Dropdown>
            </div>
            <div class="col-span-12 sm:col-span-9">
              <input-default
                v-model="estadoOrigem"
                :label="$t('sectionNcm.Create.StateOrigin')"
                disabled="true"
              />
            </div>
            <div class="col-span-12 sm:col-span-3">
              <Dropdown
                :Items="dropdownItemsEstados"
                v-model="viewModel.Estados[0].ufDestinoId"
                :valueDefault="$t('sectionNcm.Create.Uf')"
                :label="$t('sectionNcm.Create.Uf')"
                @ItemSelectId="DropdownSelected($event, 'UfDestino')"
              >
              </Dropdown>
            </div>
            <div class="col-span-12 sm:col-span-9">
              <input-default
                v-model="estadoDestino"
                :label="$t('sectionNcm.Create.StateDestination')"
                disabled="true"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- outras informações -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4 mt-3">
        <div class="col-span-8 border-solid border-b border-gray-300">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 xl:col-span-6">
              <Dropdown
                :Items="dropdownItemsCst"
                v-model="viewModel.Estados[0].cstId"
                :valueDefault="$t('sectionNcm.Create.Cst')"
                :label="$t('sectionNcm.Create.Cst')"
                @ItemSelectId="DropdownSelected($event, 'Cst')"
              >
              </Dropdown>
            </div>
            <div class="col-span-12 xl:col-span-6">
              <Dropdown
                :Items="dropdownItemsCsosn"
                v-model="viewModel.Estados[0].csosnId"
                :valueDefault="$t('sectionNcm.Create.Csosn')"
                :label="$t('sectionNcm.Create.Csosn')"
                @ItemSelectId="DropdownSelected($event, 'Csosn')"
              >
              </Dropdown>
            </div>
          </div>
        </div>
        <div class="col-span-8"></div>
      </div>
      <!-- obrigatorios -->
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8 border-solid border-b border-gray-300">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 lg:col-span-3">
              <input-default
                v-model="viewModel.Estados[0].aliquotaICMS"
                :rules="{ required: true, decimal: [24, 5], positive: true }"
                :label="$t('sectionNcm.Create.IcmsPercentage')"
                type="number"
              />
            </div>
            <div class="col-span-12 lg:col-span-3">
              <input-default
                v-model="viewModel.Estados[0].aliquotaICMSInterna"
                :rules="{ required: true, decimal: [24, 5], positive: true }"
                :label="$t('sectionNcm.Create.InternationalIcms')"
                type="number"
              />
            </div>
            <div class="col-span-12 lg:col-span-3">
              <input-default
                v-model="viewModel.Estados[0].aliquotaMVA"
                :rules="{ required: true, decimal: [10, 2], positive: true }"
                :label="$t('sectionNcm.Create.MvaPer')"
                type="number"
              />
            </div>
            <div class="col-span-12 lg:col-span-3">
              <input-default
                v-model="viewModel.Estados[0].aliquotaFCP"
                :rules="{ required: true, decimal: [24, 5], positive: true }"
                :label="$t('sectionNcm.Create.FcpPercent')"
                type="number"
              />
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </section>
</template>

<script type="module">
import InputDefault from "@core/components/commons/inputs/InputDefault";
import Dropdown from "@/@core/components/commons/selectDropdown/Dropdown.vue";

import { required } from "@validations";
import { BForm } from "bootstrap-vue";

export default {
  name: "SupplementaryInformationSupplier",
  components: {
    BForm,
    InputDefault,
    Dropdown,
  },
  props: {
    viewModel: {
      type: Object,
    },
  },
  data() {
    return {
      dropdownItemsCst: [],
      dropdownItemsCsosn: [],
      dropdownItemsEstados: [],
      estadoOrigem: null,
      estadoDestino: null,
      required,
    };
  },
  created() {
    this.ObterSeletorEstados();
    this.ObterSeletorTributosCst();
    this.ObterSeletorTributosCsosn();
  },
  methods: {
    ObterSeletorTributosCst() {
      this.$http({
        url: `/tributo/obter-seletor-tributos-tipo/${0}`,
        method: "GET",
      }).then((res) => {
        this.dropdownItemsCst = res.data;
      });
    },
    ObterSeletorTributosCsosn() {
      this.$http({
        url: `/tributo/obter-seletor-tributos-tipo/${1}`,
        method: "GET",
      }).then((res) => {
        this.dropdownItemsCsosn = res.data;
      });
    },
    ObterSeletorEstados() {
      this.$http({
        url: `/estado/obter-seletor-estados`,
        method: "GET",
      }).then((res) => {
        this.dropdownItemsEstados = res.data;
      });
    },

    BuscaNomeEstado(id, campo) {
      this.$http({
        url: `/estado/obter/${id}`,
        method: "GET",
      }).then((res) => {
        if (campo == "UfOrigem") {
          this.estadoOrigem = res.data.nome;
          this.viewModel.Estados[0].ufOrigem = this.estadoOrigem;
        } else if (campo == "UfDestino") {
          this.estadoDestino = res.data.nome;
          this.viewModel.Estados[0].ufDestino = this.estadoDestino;
        }
      });
    },
    DropdownSelected(item, tipo) {
      if (tipo == "UfOrigem") {
        this.viewModel.Estados[0].ufOrigemId = item;
        this.BuscaNomeEstado(this.viewModel.Estados[0].ufOrigemId, "UfOrigem");
      } else if (tipo == "UfDestino") {
        this.viewModel.Estados[0].ufDestinoId = item;
        this.BuscaNomeEstado(
          this.viewModel.Estados[0].ufDestinoId,
          "UfDestino"
        );
      } else if (tipo == "Cst") {
        this.viewModel.Estados[0].cstId = item;
        this.viewModel.Estados[0].cst = item.descricao;
      } else if (tipo == "Csosn") {
        this.viewModel.Estados[0].csosnId = item;
        this.viewModel.Estados[0].csosn = item.descricao;
      }
    },
  },
};
</script>
