<template>
  <section class="w-full">
    <header-register
      :showButtonView="(previewButtonIsVisible = false)"
      @register="handleActivePageOperation"
      >{{ $t("sectionNcm.titleNcm") }}
    </header-register>
    <main class="w-full">
      <ManageNcmEdit />
    </main>
  </section>
</template>

<script type="module">
import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ManageNcmEdit from "@/components/ncm/components/edit/ManageNcmEdit.vue";

export default {
  name: "NcmEdit",
  components: {
    HeaderRegister,
    ManageNcmEdit,
  },
};
</script>
